import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from '../views/MainView.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: MainView
	},
	{
		path: '/search',
		name: 'search',
		component: () => import( /* webpackChunkName: "about" */ '../views/SearchView.vue')
	},
	{
		path: '/list',
		name: 'list',
		component: () => import( /* webpackChunkName: "about" */ '../views/ListView.vue')
	},
	{
		path: '/detail',
		name: 'detail',
		component: () => import( /* webpackChunkName: "about" */ '../views/DetailView.vue')
	},
	{
		path: '/privacy',
		name: 'privacy',
		component: () => import( /* webpackChunkName: "about" */ '../views/PrivacyView.vue')
	},
	{
		path: '/agreeMent',
		name: 'agreeMent',
		component: () => import( /* webpackChunkName: "about" */ '../views/AgreeMentView.vue')
	},
	{
		path: '/project',
		name: 'project',
		component: () => import( /* webpackChunkName: "about" */ '../views/ProjectView.vue')
	},
	{
		path: '/projectShow',
		name: 'projectShow',
		component: () => import( /* webpackChunkName: "about" */ '../views/ProjectDetail.vue')
	},
	{
		path: '/favs',
		name: 'favs',
		component: () => import( /* webpackChunkName: "about" */ '../views/FavoritesView.vue')
	},
	{
		path: '/mytemplates',
		name: 'mytemplates',
		component: () => import( /* webpackChunkName: "about" */ '../views/MyTemplatesView.vue')
	},
	{
		path: '/template',
		name: 'template',
		component: () => import( /* webpackChunkName: "about" */ '../views/MyTemplates.vue')
	},
	{
		path: '/add',
		name: 'add',
		component: () => import( /* webpackChunkName: "about" */ '../views/AddTemplate.vue')
	},
	{
		path: '/account',
		name: 'account',
		component: () => import( /* webpackChunkName: "about" */ '../views/AccountView.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router